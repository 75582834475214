<template>
	<div class="h-full w-full background">
		<div class="h-full w-full background-2">
			<div class="h-full w-full content flex flex-row">
				<div class="h-full w-1/2 mobile:w-full flex flex-col justify-center pl-[10%] py-[20px]">
					<span class="title">Digital showrooms for textile businesses</span>
					<span class="subtitle pr-[10%] mt-4 mb-6"
						>Your 2-in-1 textile showroom: internal collaborative library, connected to your client portal.</span
					>
					<Button
						color="white"
						height="45"
						width="160"
						size="large"
						right-icon="md-chevron_right"
						@click="bookACall"
					>
						<span class="ml-[10px]">Book a call</span>
					</Button>
				</div>
				<div class="h-full w-1/2 flex flex-col justify-center pr-[10%] mt-[-50px] mobile:hidden">
					<div class="h-1/2">
						<img src="@/assets/images/Landing/mockups.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
definePageMeta({
	layout: "landing",
});

const bookACall = () => {
	window.open("https://tengiva.com/get-a-demo");
};
</script>

<style lang="scss" scoped>
.background {
	background: linear-gradient(279deg, #031611 42.41%, #0f4b3c 97.62%);

	.background-2 {
		background: url("/images/landing-background.png");
		@apply bg-cover bg-right;

		.title {
			@apply text-white font-bold;
			font-family: Lato;
			font-size: 60px;
			font-style: normal;
			line-height: 72px; /* 120% */
		}

		.subtitle {
			@apply text-white font-bold;
			font-family: Lato;
			font-size: 32px;
			font-style: normal;
			line-height: 40px; /* 125% */
		}
	}
}
</style>
